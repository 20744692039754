<template>
  <div>
    <v-form data-vv-scope="form-license" data-testid="form-license">
      <v-card class="mb-2">
        <v-card-title>
          <span class="headline" v-html="getTitle()"></span>
        </v-card-title>
        <v-card-text>
          <v-row class="pt-3">
            <v-col
              cols="12"
              md="12"
              v-if="errors.has('form-license.general_error_field')"
            >
              <v-row>
                <v-col cols="12" md="6">
                  <v-alert
                    type="error"
                    text
                    data-testid="license-general-errors"
                  >
                    {{ errors.first("form-license.general_error_field") }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-col>
            <v-row>
              <v-col cols="12" md="6">
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="activeLicense.serial_number"
                    :error-messages="errors.collect('form-license.hubspot_key')"
                    label="Engine serial number"
                    placeholder=""
                    data-vv-name="serial_number"
                    data-vv-validate-on="change"
                    data-vv-as="Engine serial number"
                    outlined
                    v-validate="'required'"
                    dense
                    data-testid="engine-serial-number"
                  />
                </v-col>

                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model.trim="activeLicense.email"
                    :error-messages="errors.collect('form-license.email')"
                    label="Email"
                    placeholder=""
                    data-vv-name="email"
                    data-vv-validate-on="change"
                    data-vv-as="Email"
                    outlined
                    v-validate="'required'"
                    dense
                    data-testid="email"
                  />
                </v-col>

                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="activeLicense.hardware_id"
                    :error-messages="errors.collect('form-license.hardware_id')"
                    label="Hardware ID"
                    placeholder=""
                    data-vv-name="hardware_id"
                    data-vv-validate-on="change"
                    data-vv-as="Hardware ID"
                    outlined
                    v-validate="'required'"
                    dense
                    data-testid="hardware-id"
                  />
                </v-col>
              </v-col>
            </v-row>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            text
            :to="'/store/licenses'"
            data-testid="btn-close"
          >
            Close
          </v-btn>
          <v-btn
            color="success"
            text
            @click.stop="save()"
            data-testid="btn-save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import GeneralMixin from "@/mixins/general.js"
import StoreMixin from "@/mixins/store/common.js"

export default {
  props: {
    editedIndex: {
      type: [Number, String],
      default: -1,
    },
  },

  data: () => ({
    licenseTypesItems: [],
  }),

  mixins: [GeneralMixin, StoreMixin],

  created: function () {
    let self = this
    self.$store.dispatch("saveLoaderAction", true)
    self.$store.dispatch("getLicensesTypesList").then((lt) => {
      self.licenseTypesItems = lt.data.data
      self.$store.dispatch("resetLicenseItem").then(() => {
        self.$store.dispatch("saveLoaderAction", false)
      })
    })
  },

  beforeDestroy() {
    this.$store.dispatch("resetLicenseItem")
  },

  methods: {
    getTitle() {
      return this.editedIndex === -1
        ? "Add new license"
        : `<span class="blue-grey--text text--lighten-4">Edit license</span>`
    },
    createLicense(formData) {
      let self = this
      self.$store
        .dispatch("createLicense", formData)
        .then(({ data }) => {
          let params = {
            text: `License for engine <strong>${this.activeLicense.serial_number}</strong> was added successfully.`,
            show: true,
          }
          self.close().then(() => {
            self.$root.$emit("snackbar", params)
          })
        })
        .catch(({ response }) => {
          self.handleValidationErrors(response, "form-license")
        })
    },
    editLicense(formData) {
      let self = this
      self.$store
        .dispatch("editLicense", formData)
        .then(({ data }) => {
          let params = {
            text: `License for engine <strong>${this.activeLicense.serial_number}</strong> was edited successfully.`,
            show: true,
          }
          self.close().then(() => {
            self.$root.$emit("snackbar", params)
          })
        })
        .catch(({ response }) => {
          self.handleValidationErrors(response, "form-license")
        })
    },
    save() {
      let self = this
      self.$validator.errors.clear("form-license")
      self.$validator.validateAll("form-license").then((result) => {
        if (result) {
          let formData = {
            data: {
              serial_number: self.activeLicense.serial_number,
              email: self.activeLicense.email,
              hardware_id: self.activeLicense.hardware_id,
            },
          }
          if (this.editedIndex !== -1) {
            formData["slug"] = self.editedIndex
            self.editLicense(formData)
          } else {
            self.createLicense(formData)
          }
        }
      })
    },
    close() {
      let self = this
      return new Promise((resolve, reject) => {
        this.$router
          .push({ path: "/store/licenses" })
          .then(() => {
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },

  computed: {
    ...mapGetters({
      activeLicense: "activeLicense",
    }),

    ltNames() {
      return this.licenseTypesItems.map((lt) => {
        return {
          id: lt.id,
          type: this.getLicenseTypeName(lt.type),
        }
      })
    },
  },
}
</script>
